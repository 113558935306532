<template>
    <div class="people-list">
        <!-- 查詢 -->
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.status" placeholder="请选择工单类型" @change="reload" clearable>
                    <el-option v-for="item in ObjToOpt(workOrderType)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-R">
                <el-button type="primary" @click="onAdd()">新增工单类型</el-button>
            </div>
        </section>
        <!-- 表格 -->
        <Table :tableData="tableData" :column="workTypeColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="operation">
                <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
            </template>
        </Table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
        <!-- 编辑弹框 -->
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%" center>
            <!-- 表单提交 -->
            <el-form :model="params" ref="form-ref" label-width="100px">
                <section class="form-main">
                    <el-form-item label="类型名称" prop="nickName"
                        :rules="[{ required: true, message: '请输入类型名称', trigger: 'blur' }]">
                        <el-input v-model.trim="params.nickName" autocomplete="off" maxlength="8"
                            placeholder="请输入类型名称"></el-input>
                    </el-form-item>
                </section>
                <section class="form-footer">
                    <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
                </section>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import {
    getPeopleList,
    getPeopleAdd,
    getPeopleEdit,
    getStartStopPeople,
} from "@/api/clean/clean"
import { workTypeColumns } from "@/db/tableColumns";
import { ObjToOpt } from "@/utils/utils";
import { workOrderType } from "@/db/objs";
export default {
    data() {
        return {
            workTypeColumns,
            workOrderType,
            ObjToOpt,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            // table表格
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],//表格数据
            tableParams: {
                page: 1,
                pageSize: 10,
                kw: "",//查询关键字
                status: "",//店铺ID
                merchantId: "",//商户ID
                shopId: "",//店铺ID
            },
            // 弹框
            editShow: false, //弹框开关
            editTitle: "",//弹框标题
            // from表单
            params: {
                merchantId: "",//商户ID
                shopId: "",//店铺ID
                nickName: "",
                status: 1,
                mobile: "",
            },
        }
    },
    created() {
      
    },
    mounted() {
        this.getPeopleList();// 【请求】表格数据
    },
    methods: {
        // 【请求】表格数据
        getPeopleList() {
            let data = this.tableParams;
            getPeopleList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },

        // 【请求】添加保洁
        getPeopleAdd() {
            let data = this.params;
            getPeopleAdd(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    this.reload();// 【请求】表格数据
                }
            });
        },

        // 【请求】编辑保洁
        getPeopleEdit() {
            let data = this.params;
            getPeopleEdit(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    this.getPeopleList();// 【请求】表格数据
                }
            });
        },



        // 【请求】启用停用保洁人员
        getStartStopPeople(data) {
            getStartStopPeople(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.getPeopleList();// 【请求】表格数据
                }
            });
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getPeopleList();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getPeopleList();
        },

        // 【监听】表格条数点击
        onSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize;
            this.reload();
        },
        // 【监听】新增
        onAdd() {
            this.editShow = true;
            this.editTitle = "新增工单类型";
            this.params = {
                merchantId: this.params.merchantId,//店铺ID
                shopId: this.params.shopId,//店铺ID
                nickName: "",
                status: 1,
                mobile: "",
            };
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听】编辑
        onEdit(row) {
            this.editShow = true;
            this.editTitle = "编辑工单类型";
            this.params = JSON.parse(JSON.stringify(row));
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },
        // 【监听】表单提交
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.params.cleanId) {
                        this.getPeopleEdit();
                    } else {
                        this.getPeopleAdd();
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.people-list {
    padding: .15rem;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    display: flex;
    justify-content: center;
}

.code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.clear-head {
    width: .28rem;
    height: .28rem;
    border-radius: 50%;
    margin-right: .1rem;
}
</style>

<style>
.people-list .nike-name .cell {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>